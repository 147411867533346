/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";

import "./layout.module.scss";
import { Container } from "@material-ui/core";
import styles from "./layout.module.scss";
import Header from "./Header";
import Footer from "./Footer";
import { StickyContainer } from "react-sticky";
import * as classNames from "classnames";

const Layout = ({ children, page, fullWidth = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          slogan
        }
      }
    }
  `);

  const { title, slogan } = data.site.siteMetadata;

  const wrapperClassName = classNames({
    [styles.wrapper]: page !== "main"
  });

  const TagName = fullWidth ? "div" : Container;

  return (
    <div className={styles.container}>
      <StickyContainer className={styles.stickyContainer}>
        <TagName>
          <Header siteTitle={title} slogan={slogan} page={page} />
          <div className={wrapperClassName}>
            {/* <Breadcrumbx /> */}
            <div>{children}</div>
          </div>
        </TagName>
        <Footer title={title} slogan={slogan} />
      </StickyContainer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  page: PropTypes.string
};

export default Layout;
