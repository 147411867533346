import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container, AppBar, Toolbar, Typography } from "@material-ui/core"
import styles from "./footer.module.scss"
import * as classNames from "classnames"
import LoyaltyIcon from "@material-ui/icons/Loyalty"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import { FACEBOOK_URL, INSTAGRAM_URL } from "../config.js"

const Footer = ({ title, slogan }) => {
  return (
    <footer className={styles.wrapper}>
      <Container maxWidth="lg" className={styles.container}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Link to="/imprint">Impressum</Link>
          </li>
          <li className={styles.listItem}>
            <Link to="/privacy">Datenschutzerklärung</Link>
          </li>
          <li className={styles.listItem}>
            © {new Date().getFullYear()}, {title} - {slogan}
          </li>
          <li className={styles.listItem}>
            <a href={FACEBOOK_URL} title="Facebook Reiseziel Deutschland" target="_blank"><FacebookIcon /></a>
          </li>
          <li className={styles.listItem}>
            <a href={INSTAGRAM_URL} title="Instagram Reiseziel Deutschland" target="_blank"><InstagramIcon /></a>
          </li>

        </ul>
      </Container>
    </footer>
  )
}

export default Footer
