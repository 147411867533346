import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, AppBar, Toolbar, Typography } from "@material-ui/core";
import styles from "./header.module.scss";
import * as classNames from "classnames";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import Girl from "../assets/travel_girl_arriving.svg";
import Dude from "../assets/travel_dude2.svg";

const Header = ({ siteTitle, slogan, page }) => {
  const [isFixed, setIsFixed] = useState(false);

  const onScroll = e => {
    const isTopOfPage = window.pageYOffset > 0;
    setIsFixed(isTopOfPage);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    window.dispatchEvent(new CustomEvent("scroll"));
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const appBarClassName = classNames(styles.wrapper, {
    [styles.isFixed]: isFixed,
  });

  const titleClassName = classNames(styles.title, {
    [styles.startPage]: page === "main",
  });
  const sloganClassName = classNames(styles.slogan, {
    [styles.startPage]: page === "main",
  });

  const dudeClassName = classNames(styles.person, styles.dude);
  const girlClassName = classNames(styles.person, styles.girl);

  return (
    <AppBar color="transparent" className={appBarClassName}>
      <Container maxWidth="lg">
        <Toolbar>
          <div className={girlClassName}><Girl /></div>
          <Link
            to="/"
            style={{
              color: "#333",
              textDecoration: "none"
            }}
            className={styles.link}
          >
            <h1 className={titleClassName}>
              {siteTitle}{" "}
              <LoyaltyIcon fontSize="large" className={styles.item} />
            </h1>
            <div className={sloganClassName}>{slogan}</div>
          </Link>
          {/* <div className={dudeClassName}><Dude /></div> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  slogan: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ""
};

export default Header;
